<template>
  <v-container>
    <v-row justify="center">
      <v-col class="text-center" md="6">
        <v-btn block color="warning" outlined @click="$router.push('/menu')"
          >volver</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12">
        <h3>INVENTARIO GLOBAL DE COMPAÑIA</h3>
        <v-text-field
          dense
          label="BUSCAR IMPLEMENTO"
          v-model="buscar_items"
        ></v-text-field>
        <v-data-table
          dense
          :headers="headers_bodega"
          :items="items_bodega"
          class="elevation-5"
          sort-by="nSello"
          :search="buscar_items"
          item-key="id_asignacion"
        >
        <template v-slot:[`item.fecha_asignacion`]="{item}">
            <span>{{$dayjs(item.fecha_asignacion).format("DD-MM-YYYY")}}</span>
          </template>
          <template v-slot:[`item.fecha_estado`]="{item}">
            <span>{{$dayjs(item.fecha_estado).format("DD-MM-YYYY")}}</span>
          </template>
          <template v-slot:[`item.asignadoA`]="{item}">
            <span>{{ item.nombres }} {{ item.apellido1 }}</span>
          </template>
          <template v-slot:[`item.nombre_tipo_item`]="{ item }">
            <span>{{ item.nombre_tipo_item }} ({{item.nombre_subtipo }})</span>
          </template>
          <template v-slot:[`item.accion`]="{item}">
            <v-row>
              <v-col md="12">
                <v-btn color="warning" x-small @click="abrirHistorial(item)"><v-icon small left>mdi-eye</v-icon> historial</v-btn>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
        v-model="dialogHistorial"
        persistent
        :overlay="false"
        max-width="1120px"
        transition="dialog-transition"
      >
        <v-card>
            <v-row class="mx-5 mt-5">
              <v-col >
                 <h4>Historial </h4> 
              </v-col>
            </v-row>

            <v-row class="mx-10 mb-1 mt-n5">
              <v-col>
                <h6>{{dataHistorial.nombre_tipo_item}} </h6>
                <h6>Asignado actualmente a: {{dataHistorial.nombres}} {{dataHistorial.apellido1}} {{dataHistorial.apellido2}}</h6>
              </v-col>
            </v-row>
          <v-card-text>
            <v-data-table
              dense
              :headers="cabeceraHistorial"
              :items="items_bodega"
              :sort-by="['fecha']"
              :sort-desc="[true]"
              class="elevation-5"
            >
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="dialogHistorial = false"
              >CERRAR</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      dialogHistorial:false,
      dataHistorial:{
      },
      cabeceraHistorial:[
        { text: "Realisado por", value: "" },
        { text: "Fecha", value: "" },
        { text: "Estado", value: "" },
    ],
      historial:"",
      buscar_items: "",
      items_bodega: [],
      headers_bodega: [        
        { text: "Item", value: "type.name" },
        { text: "Talla", value: "size" },
        { text: "Marca", value: "make.name" },
        { text: "Modelo", value: "model.name" },
        { text: "AsignadoA", value: "assigned_to.name"},
        { text: "Asignado", value: "fecha_asignacion" },
        { text: "Revisado", value: "fecha_estado" },
        { text: "Estado", value: "condition.condition" },
        { text: "Accion", value: "accion" },
      ],
      items_bodega: [],
    };
  },
  methods: {
    consultabodegaprincipal: function (data) {
      let personaConsultada = 3;
      axios
        .get(`${process.env.VUE_APP_BASE_URL}/api/item/getAll`)
        .then((response) => {
          console.log(response.data);
          this.items_bodega = response.data
        });
    },
    abrirHistorial: function(data){
      console.log(data);
      this.dialogHistorial = true
      this.dataHistorial={...data}
    }
  },
  created() {
    this.consultabodegaprincipal();
  },
};
</script>
